<template>
  <div>
    <data-iterator :headers="headers" :payload="payload" class="ma-2">
      <template #message="{ item }">
        <v-btn icon @click="viewHandler(item)" color="primary" small>
          <v-icon small> mdi-message </v-icon>
        </v-btn>
      </template>
    </data-iterator>
    <v-dialog v-model="viewRecordDialog" width="600">
      <v-card>
        <v-card-text class="pa-5 black--text">
          <v-layout row wrap align-center class="ma-0 mb-2">
            <v-flex class="d-inline-flex align-center">
              <v-icon class="mb-1 mx-n1" color="grey darken-3"> mdi-account </v-icon>
              <span class="mx-3 body-1 font-weight-black grey--text text--darken-2"> {{ payload.viewHandlerRecord.name }} </span>
            </v-flex>
            <v-flex class="text-right">
              <span class="pt-1 caption font-weight-bold indigo--text text--darken-4"> {{ payload.viewHandlerRecord.createdAt }} </span>
            </v-flex>
          </v-layout>
          <v-card max-height="50vh" class="overflow-y-auto" flat>
            <div> {{ payload.viewHandlerRecord.message }} </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      payload: {
        items: [],
        actionsList: [{
          click: this.deleteHandler,
          icon: 'mdi-delete',
          color: 'error'
        }],
        // other general variables
        viewHandlerRecord: {}
      },
      viewRecordDialog: false
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  beforeMount () {
    this.getAllRecords()
  },
  computed: {
    headers () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Email',
        value: 'email'
      }, {
        text: 'Message',
        value: 'message',
        slot: 'message'
      }, {
        text: 'Sent at',
        value: 'createdAt'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getAllRecords () {
      this.$_execute('get', 'techilex/contactus')
        .then(({ data }) => {
          this.payload.items = data.map((message) => ({
            ...message,
            createdAt: this.$_formatDate(message.createdAt, '', 'DD.MM.YYYY HH:mm')
          }))
        })
    },
    viewHandler (item) {
      this.payload.viewHandlerRecord = { ...item }
      this.viewRecordDialog = true
    },
    deleteHandler ({ _id }) {
      this.$root.$emit('delete', {
        module: 'techilex/contactus',
        _id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getAllRecords()
        }
      })
    }
  }
}
</script>
<style>
  table.techilexContactusTable tr th {
    font-size: 0.85rem;
    text-align: left;
    padding: 0 10px !important;
  }
  table.techilexContactusTable tr td {
    font-size: 0.9rem;
    color: rgb(70, 70, 70);
    padding: 0 10px !important;
  }
</style>
